<template>
  <div class="card p-10">
    <div class="d-flex justify-content-between mb-5">
      <h1>新增草稿</h1>
      <button @click="goBack()" type="button" class="btn btn-light">
        返回
      </button>
    </div>
    <div>
      <el-form
        @submit.prevent="submit()"
        :model="form"
        :rules="rules"
        ref="formRef"
        class="form"
      >
        <label class="fs-6 fw-bold mb-2">
          <span class="required">起訖時間</span>
        </label>
        <el-form-item prop="datePicker">
          <el-date-picker
            v-model="form.datePicker"
            type="datetimerange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
          >
          </el-date-picker>
        </el-form-item>

        <label class="fs-6 fw-bold mb-2">
          <span class="required">標題</span>
        </label>
        <el-form-item prop="title">
          <el-input v-model="form.title" placeholder="請輸入標題"></el-input>
        </el-form-item>

        <label class="fs-6 fw-bold mb-2">
          <span>簡介</span>
        </label>
        <el-form-item>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="請輸入簡介"
            v-model="form.summary"
          ></el-input>
        </el-form-item>

        <label class="fs-6 fw-bold mb-2">
          <span>內容</span>
        </label>
        <el-form-item>
          <editor
            v-model="form.content"
            :init="tinymceInit"
            api-key="3x7t4hj96hg3pwokkputtnt7xbqzy3pc0dq436suk0o2nx17"
          />
        </el-form-item>

        <label class="fs-6 fw-bold mb-2">
          <span>封面照</span>
        </label>
        <el-form-item>
          <input
            type="file"
            class="form-control"
            id="inputGroupFile01"
            accept="image/*"
            @input="onCoverPhotoInput($event)"
          />
          <div v-if="!isCoverPhotoEmpty" style="width: 300px">
            <img :src="showCoverPhoto" class="img-fluid my-5" alt="..." />
          </div>
        </el-form-item>

        <label class="fs-6 fw-bold mb-2">
          <span>附檔</span>
        </label>
        <el-form-item>
          <input
            type="file"
            class="form-control"
            id="inputGroupFile02"
            multiple="multiple"
            accept="image/*, .pdf"
            @input="onFilesInput($event)"
          />
        </el-form-item>

        <div class="d-flex justify-content-end">
          <button class="btn btn-success" type="submit">送出</button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";
import Editor from "@tinymce/tinymce-vue";
import { addAnnouncements } from "@/core/services/api/announcement";
import {
  uploadAnnouncementsCoverPhoto,
  uploadAnnouncementsFile,
} from "@/core/services/api/file";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  components: {
    editor: Editor,
  },
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tinymceInit = {
      language_url: "/tinymce/langs/zh_TW.js",
      language: "zh_TW",
      height: 300,
      plugins: "link",
      link_context_toolbar: true,
    };
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      datePicker: [
        {
          required: true,
          message: "請選擇起訖時間",
          trigger: "blur",
        },
      ],
      title: [
        {
          required: true,
          message: "請填寫標題",
          trigger: "blur",
        },
      ],
    });
    const form: any = reactive({
      datePicker: [],
      title: "",
      summary: "",
      content: "",
      coverPhoto: "",
      files: [],
    });
    let isCoverPhotoEmpty = ref(true);
    let showCoverPhoto = ref("");

    const onCoverPhotoInput = (file) => {
      if (!file.target.files[0]) {
        form.coverPhoto = "";
        showCoverPhoto.value = "";
        isCoverPhotoEmpty.value = true;
      } else {
        form.coverPhoto = file.target.files[0];
        showCoverPhoto.value = URL.createObjectURL(file.target.files[0]);
        isCoverPhotoEmpty.value = false;
      }
    };

    const onFilesInput = (inputFile) => {
      let inputFileLength = inputFile.target.files.length;
      form.files.splice(0);
      for (const o in inputFile.target.files) {
        form.files.push(inputFile.target.files[o]);
      }
      form.files.splice(inputFileLength, form.files.length);
    };

    const goBack = () => {
      history.back();
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增草稿嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // 新增草稿
              let addAnnouncementsRequest = {
                title: form.title,
                content: form.content,
                summary: form.summary,
                beginTime: getUtcTimeString(form.datePicker[0]),
                endTime: getUtcTimeString(form.datePicker[1]),
              };
              let addAnnouncementsResponses = await addAnnouncements(
                currentSpaceGuid!,
                addAnnouncementsRequest
              );
              let addAnnouncementGuid = addAnnouncementsResponses.guid;
              // 上傳公告封面照
              if (form.coverPhoto !== "") {
                let formData = new FormData();
                formData.set("file", form.coverPhoto, form.coverPhoto.name);
                await uploadAnnouncementsCoverPhoto(
                  addAnnouncementGuid,
                  formData
                );
              }
              // 上傳公告檔案
              if (form.files.length > 0) {
                for (const o of form.files) {
                  let formData = new FormData();
                  formData.set("file", o, o.name);
                  await uploadAnnouncementsFile(addAnnouncementGuid, formData);
                }
              }

              await Swal.fire("草稿新增成功!", "", "success");
              goBack();
            }
          });
        }
      });
    };
    return {
      rules,
      formRef,
      form,
      submit,
      tinymceInit,
      onCoverPhotoInput,
      onFilesInput,
      isCoverPhotoEmpty,
      showCoverPhoto,
      goBack,
    };
  },
});
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>