
import { defineComponent, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";
import Editor from "@tinymce/tinymce-vue";
import { addAnnouncements } from "@/core/services/api/announcement";
import {
  uploadAnnouncementsCoverPhoto,
  uploadAnnouncementsFile,
} from "@/core/services/api/file";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  components: {
    editor: Editor,
  },
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tinymceInit = {
      language_url: "/tinymce/langs/zh_TW.js",
      language: "zh_TW",
      height: 300,
      plugins: "link",
      link_context_toolbar: true,
    };
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      datePicker: [
        {
          required: true,
          message: "請選擇起訖時間",
          trigger: "blur",
        },
      ],
      title: [
        {
          required: true,
          message: "請填寫標題",
          trigger: "blur",
        },
      ],
    });
    const form: any = reactive({
      datePicker: [],
      title: "",
      summary: "",
      content: "",
      coverPhoto: "",
      files: [],
    });
    let isCoverPhotoEmpty = ref(true);
    let showCoverPhoto = ref("");

    const onCoverPhotoInput = (file) => {
      if (!file.target.files[0]) {
        form.coverPhoto = "";
        showCoverPhoto.value = "";
        isCoverPhotoEmpty.value = true;
      } else {
        form.coverPhoto = file.target.files[0];
        showCoverPhoto.value = URL.createObjectURL(file.target.files[0]);
        isCoverPhotoEmpty.value = false;
      }
    };

    const onFilesInput = (inputFile) => {
      let inputFileLength = inputFile.target.files.length;
      form.files.splice(0);
      for (const o in inputFile.target.files) {
        form.files.push(inputFile.target.files[o]);
      }
      form.files.splice(inputFileLength, form.files.length);
    };

    const goBack = () => {
      history.back();
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增草稿嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // 新增草稿
              let addAnnouncementsRequest = {
                title: form.title,
                content: form.content,
                summary: form.summary,
                beginTime: getUtcTimeString(form.datePicker[0]),
                endTime: getUtcTimeString(form.datePicker[1]),
              };
              let addAnnouncementsResponses = await addAnnouncements(
                currentSpaceGuid!,
                addAnnouncementsRequest
              );
              let addAnnouncementGuid = addAnnouncementsResponses.guid;
              // 上傳公告封面照
              if (form.coverPhoto !== "") {
                let formData = new FormData();
                formData.set("file", form.coverPhoto, form.coverPhoto.name);
                await uploadAnnouncementsCoverPhoto(
                  addAnnouncementGuid,
                  formData
                );
              }
              // 上傳公告檔案
              if (form.files.length > 0) {
                for (const o of form.files) {
                  let formData = new FormData();
                  formData.set("file", o, o.name);
                  await uploadAnnouncementsFile(addAnnouncementGuid, formData);
                }
              }

              await Swal.fire("草稿新增成功!", "", "success");
              goBack();
            }
          });
        }
      });
    };
    return {
      rules,
      formRef,
      form,
      submit,
      tinymceInit,
      onCoverPhotoInput,
      onFilesInput,
      isCoverPhotoEmpty,
      showCoverPhoto,
      goBack,
    };
  },
});
